<template>
  <div class="wrapper">
    <img
      class="bg"
      src="http://sc2.hexiaoxiang.com/common/multi_h5/pages/smartpen/images/download_bg.png"
    />
    <div @click="downloadHandle()">
      <img
        class="btn"
        src="http://sc2.hexiaoxiang.com/common/multi_h5/pages/smartpen/images/download_btn.png"
      />
    </div>
    <div v-if="showHint">
      <div class="mask"></div>
      <img
        class="hint-img"
        src="http://sc2.hexiaoxiang.com/common/multi_h5/pages/smartpen/images/public_wx.png"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      iosData: {
        openUrl: 'https://www.hexiaoxiang.com/app/smartpen/download?uid=1',
        downloadUrl: 'itms-apps://itunes.apple.com/cn/app/id1541456814?mt=8'
      },
      androidData: {
        openUrl: 'hxx://smartpen/app',
        downloadUrl: 'https://sc2.hexiaoxiang.com/apk/smartpen/smartpen_v1.3.0_default.apk'
        // downloadUrl: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.hxx.smartpen'
      },
      showHint: false
    }
  },
  computed: {
    browserEnv() {
      return {
        isPhone: Boolean(
          /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
        ),
        isWeiXin: Boolean(navigator.userAgent.match(/MicroMessenger/gi)),
        isMiniProgram: Boolean(navigator.userAgent.match(/miniProgram/gi)),
        isAli: Boolean(navigator.userAgent.match(/AlipayClient/gi)),
        isAndroid: Boolean(navigator.userAgent.match(/android/gi)),
        isIPhone: Boolean(navigator.userAgent.match(/iphone|ipod/gi)),
        isIPad: Boolean(navigator.userAgent.match(/ipad|Macintosh/gi)),
        isIOS: Boolean(
          navigator.userAgent.match(/iphone|ipod|ipad|Macintosh/gi)
        )
      }
    }
  },
  created() {
    this.showHint = this.browserEnv.isWeiXin
  },
  methods: {
    downloadHandle() {
      let that = this
      if(that.browserEnv.isAndroid) {
        // android 先尝试唤起app，唤起失败后去下载
        location.href = that.androidData.openUrl
        setTimeout(() => {
          location.href = that.androidData.downloadUrl
        }, 2000)
      } else if(that.browserEnv.isIOS) {
        // ios在同一个域名内，唤起和下载不能共存，所以此处只做下载操作
        location.href = that.iosData.downloadUrl
      }
      
    },
  }
}
</script>
<style lang="scss" scoped>
image,
div {
  margin: 0px !important;
  padding: 0px !important;
  border: 0px;
  top: 0px;
  left: 0px;
  overflow-x: hidden;
}

img {
  display: block;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0px !important;
  padding: 0px !important;
  left: 0px;
  top: 0px;
  overflow-x: hidden;
}

.bg {
  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
  left: 0px;
  top: 0px;
  overflow-x: hidden;
}

.mask {
  background-color: rgb(0, 0, 0);
  opacity: 0.75;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.btn {
  position: fixed !important;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: 350px;
  height: 88px;
  bottom: 30%;
}

.hint-img {
  z-index: 3;
  position: fixed;
  width: 100vw;
  left: 0px;
  right: 0px;
  top: 0px
}
</style>
